.window-list > div {
  overflow-y: auto;
  overflow-x: hidden;
}

.list-users > div {
  height: 230px !important;
}

.list-view-more > div {
  height: auto !important;
}
